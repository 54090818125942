import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout";
import SearchBarActualite from "../components/search/search-bar-actualite";
import ListActivite from "../components/activite/activite";
import { debounce } from "lodash";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { formatFilterText, itextContains } from "../utils/utils";
import File from "../components/file";

type DataProps = {
    directus: {
        news: [
            content: any,
            id: string,
            image: {},
            date_published: string,
            categories: {},
            title: string
        ];
    };
};

interface IPage {
    id?: number;
    content?: any;
    title?: string;
    slug?: string;
}

const Page = ({ pageContext }: any) => {
    const { id, slug, content, name, image } = pageContext;
    // const getChildren = () => {
    //     if (bLoadingUserInStore) {
    //         return (
    //             <div
    //                 className="tw-border-t-2 tw-border-t-black tw-bg-primary tw-text-white tw-h-[67vh] tw-border-b-2 tw-border-solid tw-border-b-[#67648A] sm:tw-h-[60vh]"
    //                 style={{ height: height + "px", width: "auto" }}
    //             ></div>
    //         );
    //     }

    //     return isConnected() ? (
    //         <DeconnexionForm height={height} />
    //     ) : (
    //         <ConnexionForm {...state} onLogin={onLogin} height={height} />
    //     );
    // };

    return (
        <Layout>
            <div className="tw-w-full tw-h-full tw-bg-slate-600">
                <img
                    src={image?.imageFile?.publicURL}
                    className="tw-w-full tw-h-auto tw-max-h-[550px] sm:tw-h-auto tw-object-cover"
                />
            </div>
            <div className="">
                <h4 className="tw-px-12 tw-pt-4 tw-text-primary">{name}</h4>
                <div className="tw-px-7 tw-pb-8">
                    <div
                        className="tw-p-5 "
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </div>
            </div>
        </Layout>
    );
};

export default Page;
